<template>
    <div class="content_container customer_terms">
        <div class="content">
            <div class="banner">
                <h1>금융소비자보호</h1>
            </div>
			<div class="m_terms_menu_wrapper">
				<ul class="terms_menu">
					<li class="current">
						<a @click="toggleMenu">위법계약해지권</a>
					</li>
					<li>
						<router-link to="/customer/financeprotect" @click="toggleMenu">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego" @click="toggleMenu">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw" @click="toggleMenu">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen" @click="toggleMenu">자료열람청구권</router-link>
					</li>
				</ul>
			</div>
            <section class="terms">
				<ul class="terms_menu">
					<li>
						<router-link to="/customer/financeprotect">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen">자료열람청구권</router-link>
					</li>
					<li class="current">
						<router-link to="/customer/termination">위법계약해지권</router-link>
					</li>
				</ul>
				<div>
					<div class="text_box"> 
						<div class="csfont2 title" style="color:#000;">위법계약해지권</div>

						<br><br>

						<div class="csfont1">1. 위법계약 해지권이란?</div>
						<div class="csfont2">
						- 금융상품판매업자등(금융회사, 신용카드·대출·리스할부모집인)이 「금융소비자 보호에 관한 법률」의 적합성원칙(법 제17조 제3항), 적정성원칙(법 제18조 제2항), 설명의무(법 제19조 제1항, 제3항), 불공정영업행위의 금지(법 제20조 제1항), 부당권유 금지(법 제21조)를 위반하여 금융상품에 관한 계약을 체결한 경우, 금융소비자가 계약 해지를 요구할 수 있는 권리
						</div>
						<br><br>

						<div class="csfont1">2. 위법계약 해지 대상 금융상품</div>
						<div class="csfont2">
						- 대출, 할부금융, 시설대여(리스), 오토론 등<br>
						*’21.03.25일 이후 계약이 체결이 되었으며, 금융소비자와 금융회사 간 계속적 거래가 이루어지고, 금융소비자가 해지 시 재산상 불이익이 발생하는 금융상품
						</div>
						<br><br>

						<div class="csfont1">3. 해지신청 가능기한</div>
						<div class="csfont2">
						- 금융소비자가 계약 체결에 대한 위반사항을 안 날로부터 1년 이내에 신청 가능<br>
						(단, 해당 기간이 계약 체결일로부터 5년이내의 범위에 있어야 함)
						</div>
						<br><br>

						<div class="csfont1">4. 신청 방법</div>
						<div class="csfont2">
						- 금융소비자가 계약 체결에 대한 위반사항을 안 날로부터 1년 이내에 신청 가능<br>
						- 당사양식은(고객센터>서식자료실)로 가셔서 다운로드 하시면 됩니다.
						</div>
						<br><br>

						<div class="csfont1">5. 처리 프로세스</div>
						<div class="csfont2">
						① 신청서 제출(계약체결 부·점/콜센터)<br>
						② 위법계약해지여부 검토<br>
						- 거절 시 고객 앞 거절사유 및 거절 안내<br>
						- 수락 시 고객 안내 및 계약해지<br><br>
						※ 해지 신청서 접수일로부터 10일 이내에 처리 결과 안내 예정<br>
						→ 계약해지 수락 시 소비자는 해지 관련 비용 없이 계약 해지 가능하나, 계약해지 후 당사가 소비자에게 지급해야할 금액에는 대출이자 등 서비스 제공과정에서 발생한 비용은 포함되지 않음
						</div>
						<br><br>

					</div>
				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Termination',
	methods:{
		toggleMenu(){
			document.querySelector('.m_terms_menu_wrapper .terms_menu').classList.toggle('on');
		},
	}
}
</script>

